import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";
import sasu from '../../../images/blue-logo.png'

/// images
//import logo from "../../../images/logo.png";
//import logoText from "../../../images/logo-text.png";

const NavHader = () => {
   const [toggle, setToggle] = useState(false);
   return (
      <div className="nav-header">
	  { /*  <Link to="/" className="brand-logo">
            <img className="logo-abbr" src={logo} alt="" />
            <img className="logo-compact" src={logoText} alt="" />
			<img className="brand-title" src={logoText} alt="" /> */}
			
			<Link to={"#"} className="brand-logo">
				<img src={sasu} width={80} alt="SasuDigital" />
            </Link>
         <div className="nav-control" onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
