import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'


const Credit = () => {
    const [amount, setAmount] = useState('')
    const [userID, setUserID] = useState('')
    const [users, setUsers] = useState([])


  // const urls = 'http://localhost:5000'
  const urls = 'https://sasu.nochsolutions.com.ng'

    //fetch all users
   const fetchUser = () => axios.get(`${urls}/apiV1/admin/allUsers`).then((res) => {
    setUsers(res.data)
    console.log(res.data)
    })


    const onSubmit = (e) => {
        e.preventDefault()
        axios.post(`${urls}/apiV1/admin/creditUser`, {
            userID:userID,
            amount:amount
        }).then((response) => {
            alert(response.data.message)
            setAmount('')
    
        })

    }

    useEffect(() => {
        fetchUser()
    },[])

  return (
    <div>
        <div className='py-3 px-3 mt-2'>
    <div className='col-md-6 col-sm-12'>
    <div className='card shadow'>
        <div className='card-header'>
            <h3 className='card-title lead text-primary'>Credit a User</h3>
        </div>
        <div className='card-body'>
            
            <form onSubmit={onSubmit}>
                <div className='form-group'>
                <label className='lead'>Select User</label>
              <select id='items' name='userID' className='form-control form-select' onChange={(e) => setUserID(e.target.value)}>
                <option defaultValue={userID}>Select User</option>
                {users.map((items) => (
                    <option value={items.id}>{items.username}</option>
                ))}
              </select>
              </div>

              <div className='form-group'>
              <label className='lead'>Amount</label>
                <input className='form-control' type='number' step='any' name='amount'  value={amount}  onChange={(e) => setAmount(e.target.value)} />
              </div>

              <div className='btn-group'>
                <input type='submit' className='btn btn-primary btn-block' value='Credit User' />
              </div>
            </form>
            </div>

        </div>
    </div>
</div></div>
  )
}

export default Credit