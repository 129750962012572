/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import Home from "../../components/Users/Home";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
      "",
      "dashboard-dark",
      "my-wallet",
      "coin-details",
      "portfolio",
      "market-capital",
      "task",
    ],
      users = [
        "user-management",
        "credit-user",

      ],
      transactions =[
        "sasu-transactions",
        "main-transactions",
        "add-transaction"
      ]

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "" ? "mm-active" : ""}`} to="/" >Dashboard</Link>
                </li>

              </ul>
            </li>


            <li className={`${users.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-user"></i>
                <span className="nav-text">User Management</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "user-management" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/user-management">Users</Link>
                </li>

                <li>
                  <Link className={`${path === "credit-user" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/credit-user">Credit User</Link>
                </li>
              </ul>
            </li>

            <li className={`${transactions.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-note"></i>
                <span className="nav-text">Transaction</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "sasu-transactions" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/sasu-transactions">Sasu Transactions</Link>
                </li>

                <li>
                  <Link className={`${path === "main-transactions" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/main-transactions">Transactions Flow</Link>
                </li>
              </ul>
            </li>

          </MM>

        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
