import React, { useState } from 'react'
/// React router dom
import {Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
//import Home from './components/Dashboard/Home'
//import MyWallet from './components/Dashboard/MyWallet'
//import Invoices from './components/Dashboard/Invoices'
//import CardsCenter from './components/Dashboard/CardsCenter'
//import Transactions from './components/Dashboard/Transactions'
//import TransactionsDetails from './components/Dashboard/TransactionsDetails'

/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Wallet from "./components/Dashboard/Wallet";
import CoinDetails from "./components/Dashboard/CoinDetails";
import Portofolio from "./components/Dashboard/Portofolio";
import MarketCapital from "./components/Dashboard/MarketCapital";

import Task from './components/Dashboard/Task';



//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';

//Users
import UserHome from './components/Users/Home'
import Credit from './components/Users/Credit'
import SasuTransactions from './components/Transactions/SasuTransactions'
import MainTransactions from './components/Transactions/MainTransactions'
import AddTransaction from './components/Transactions/AddTransaction'


const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: '', component: Home },
    { url: 'dashboard', component: Home },
    { url: 'dashboard-dark', component: DashboardDark },
    { url: "my-wallet", component: Wallet },
    { url: "coin-details", component: CoinDetails },
    { url: "portfolio", component: Portofolio },
    { url: "market-capital", component: MarketCapital },
	{ url: 'task', component: Task },


  //Users
  {url: 'user-management', component: UserHome},
  {url: 'credit-user', component: Credit},

  //transactions
  {url: 'sasu-transactions', component: SasuTransactions},
  {url: 'main-transactions', component: MainTransactions},
  {url: 'add-transaction', component: AddTransaction},


  //withdrawal


   
  ]

  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}

export default Markup
