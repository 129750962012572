import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import useSWR from 'swr'

const SasuTransactions = () => {
   const [data, setData] = useState([])
    //const urls = 'http://localhost:5000/apiV1/admin/trans'
    const urls = 'https://sasu.nochsolutions.com.ng/apiV1/admin/trans'
    
     
 const getData =() => {
        axios.get(urls)
          .then((res) => {
            setData(res.data)
            console.log(res.data)
          })
        }
   useEffect(() => {
    getData()

   },[])
      var x=0;
    
      return (
        <>
          <div className='py-3 px-3 mt-2'>
        <div className='col-md-12 col-sm-12'>
        <Link to={'/add-transaction'}><button className='btn btn-primary float-end mb-3'>Add Transaction</button></Link>
        <div className='card shadow'>
            <div className='card-header'>
                <h3 className='card-title lead text-primary'>Sasu Transactions</h3>
            </div>
            <div className='card-body'>
            {data == null ? 'No data available': !data ? (
                'Loading...'
              ) : (
                <table className='table table-hover table-striped'>
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Transaction Amount</th>
                        <th>Amount</th>
                        <th>Details</th>
                        <th>Benificiary</th>
                        <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data.map((items) =>
                            <tr> 
                                <td>{++x}</td>
                                <td>{items.transAmount}</td>
                                <td>{items.amount}</td>
                                <td>{items.details}</td>
                                <td>{items.codes}</td>
                                <td>{items.createdAt}</td>
                            </tr>
                        
                         )}
                    </tbody>
                </table>
                 )}
                </div>
    
                </div>
                </div>
                </div>
       
        </>
        
       
)}

export default SasuTransactions