import React, { useEffect, useState } from 'react'
// imeges

import bit_1 from "./../../../../images/svg/bitcoin-1.svg";
import bit_2 from "./../../../../images/svg/ethereum-1.svg";
import bit_3 from "./../../../../images/svg/ripple-1.svg";
import bit_4 from "./../../../../images/svg/litecoin-1.svg";
import axios from 'axios';

const Summary = () => {
   const [count, setCount] = useState('')
   const [coin, setCoin] = useState('')
   const [transact, setTransact] = useState('')

   // const urls = 'http://localhost:5000'
    const urls = 'https://sasu.nochsolutions.com.ng'

   //fetch count users
   const fetcher = () => axios.get(`${urls}/apiV1/admin/countUsers`).then((res) => {
       setCount(res.data)
      
   })

    //fetch total coin
    const coins = () => axios.get(`${urls}/apiV1/admin/allCoins`).then((res) => {
       setCoin(res.data[0]['total_coin'])
   })

       //fetch transaction
       const transacts = () => axios.get(`${urls}/apiV1/admin/a/countTrans`).then((res) => {
         setTransact(res.data)
     })
  
   
   
   


   useEffect(() => {
       fetcher()
       coins()
       transacts()
   },[])
  return (
    <>
     <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                  <div className="card overflow-hidden">
                     <div className="card-header border-0 pb-4">
                        <div className="mr-auto">
                           <h2 className="text-black mb-2 font-w600">
                              {count}
                           </h2>
                           <p className="mb-1 fs-13">
                             
                              Total Users
                           </p>
                        </div>
                        <img src={bit_1} alt="" />
                     </div>
                     <div className="card-body p-0">
                        {/* <canvas id="widgetChart1" height={75} /> */}
                        
                     </div>
                  </div>
               </div>
               <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                  <div className="card overflow-hidden">
                     <div className="card-header border-0 pb-4">
                        <div className="mr-auto">
                           <h2 className="text-black mb-2 font-w600">{coin}</h2>
                           <p className="mb-1 fs-13">
                             
                             Total Coin Wallets
                           </p>
                        </div>
                        <img src={bit_2} alt="" />
                     </div>
                     <div className="card-body p-0">
                        {/* <canvas id="widgetChart2" height={75} /> */}
                       
                     </div>
                  </div>
               </div>
               <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                  <div className="card overflow-hidden">
                     <div className="card-header border-0 pb-4">
                        <div className="mr-auto">
                           <h2 className="text-black mb-2 font-w600">0</h2>
                           <p className="mb-1 fs-13">
                             
                              Total Pending Withdrawal
                           </p>
                        </div>
                        <img src={bit_3} alt="" />
                     </div>
                     <div className="card-body p-0">
                        {/* <canvas id="widgetChart3" height={75} /> */}
                       
                     </div>
                  </div>
               </div>
               <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                  <div className="card overflow-hidden">
                     <div className="card-header border-0 pb-4">
                        <div className="mr-auto">
                           <h2 className="text-black mb-2 font-w600">
                              {transact === undefined ? 0 : transact}
                           </h2>
                           <p className="mb-1 fs-13">
                              Total Transactions
                           </p>
                        </div>
                        <img src={bit_4} alt="" />
                     </div>
                     <div className="card-body p-0">
                        {/* <canvas id="widgetChart4" height={75} /> */}
                        
                     </div>
                  </div>
               </div>
    </>
  )
}

export default Summary