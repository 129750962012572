import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";




//components
import Summary from "./components/Summary";

const Home = () => {
   const [data, setData] = useState([])

   //  const urls = 'http://localhost:5000/apiV1/admin/allTransaction'
   const urls = 'https://sasu.nochsolutions.com.ng/apiV1/admin/allTransaction'
    
     
    const getData =() => {
      axios.get(urls)
        .then((res) => {
          setData(res.data)
          console.log(res.data)
        })
      }

      useEffect(() => {
         getData()
       
        },[])
             var x=0;

   return (
      <Fragment>
         <>
            <div className="row">
              <Summary />
              
               <div className="col-xl-12 col-xxl-12 col-lg-12">
                  <div className="card">
                     
                        <div className="card-header">
                           <div>
                              <h4 className="fs-20 text-black">
                                 Recent Transactions
                              </h4>
                              
                           </div>
                         
                        </div>
                        <div className="card-body ">
                       
                       
            {data == null ? 'No data available': !data ? (
                'Loading...'
              ) :  (
                <table className='table table-hover table-striped '>
                    <thead>
                        <tr>
                            <th>S/N</th>
                        <th>Amount</th>
                        <th>Transaction Type</th>
                        <th>Made By</th>
                        <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data.map((items) =>
                            <tr> 
                                <td>{++x}</td>
                                <td>{items.amount}</td>
                                <td>{items.transactionType}</td>
                                <td>{items.madeBy}</td>
                                <td>{items.createdAt}</td>
                            </tr>
                        
                         )}
                    </tbody>
                </table>
                 )}
                
                              
                             
                          
                        </div>
                    
                  </div>
               </div>
             
             
              
            </div>
         </>
      </Fragment>
   );
};

export default Home;
