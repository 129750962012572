import axios from 'axios'
import React, { useState, useEffect } from 'react'
import useSWR from 'swr'

const MainTransactions = () => {
  const [data, setData] = useState([])

    //const urls = 'http://localhost:5000/apiV1/admin/allTransaction'
    const urls = 'https://sasu.noschsolutions.com.ng/apiV1/admin/allTransaction'
    
     
    const getData =() => {
      axios.get(urls)
        .then((res) => {
          setData(res.data)
          console.log(res.data)
        })
      }
 useEffect(() => {
  getData()

 },[])
      var x=0;
    
      return (
        <>
          <div className='py-3 px-3 mt-2'>
        <div className='col-md-12 col-sm-12'>
        <div className='card shadow'>
            <div className='card-header'>
                <h3 className='card-title lead text-primary'>All Transactions</h3>
            </div>
            <div className='card-body'>
            {data == null ? 'No data available': !data ? (
                'Loading...'
              ) :  (
                <table className='table table-hover table-striped'>
                    <thead>
                        <tr>
                            <th>S/N</th>
                        <th>Amount</th>
                        <th>Transaction Type</th>
                        <th>Made By</th>
                        <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data.map((items) =>
                            <tr> 
                                <td>{++x}</td>
                                <td>{items.amount}</td>
                                <td>{items.transactionType}</td>
                                <td>{items.madeBy}</td>
                                <td>{items.createdAt}</td>
                            </tr>
                        
                         )}
                    </tbody>
                </table>
                 )}
                </div>
    
                </div>
                </div>
                </div>
       
        </>
  )
}

export default MainTransactions