import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'

const AddTransaction = () => {
    const [amount, setAmount] = useState('')
    const [details, setDetails] = useState('')
    const [transAmount, setTransAmount] = useState('')
    const [code, setCode] = useState('')


  // const urls = 'http://localhost:5000'
  const urls = 'https://sasu.nochsolutions.com.ng'

    const onSubmit = (e) => {
        e.preventDefault()
         axios.post(`${urls}/apiV1/admin/postTransactions`, {
            transAmount:transAmount,
            amount:amount,
            details:details,
            code:code
        }).then((response) => {

            alert(response.data.message)
            setAmount('')
    
        })

    }


   
  return (
    <div>
    <div className='py-3 px-3 mt-2'>
<div className='col-md-6 col-sm-12'>
<div className='card shadow'>
    <div className='card-header'>
        <h3 className='card-title lead text-primary'>Credit a User</h3>
    </div>
    <div className='card-body'>
        
        <form onSubmit={onSubmit}>
        <div className='form-group'>
          <label className='lead'>User Code</label>
            <input className='form-control' type='text'  name='code'  value={code}  onChange={(e) => setCode(e.target.value)} />
          </div>

          <div className='form-group'>
          <label className='lead'>Transaction Amount</label>
            <input className='form-control' type='number' step='any' name='transAmount'  value={transAmount}  onChange={(e) => setTransAmount(e.target.value)} />
          </div>

          <div className='form-group'>
          <label className='lead'>Amount for User</label>
            <input className='form-control' type='number' step='any' name='amount'  value={amount}  onChange={(e) => setAmount(e.target.value)} />
          </div>

          <div className='form-group'>
          <label className='lead'>Transaction Details</label>
          <textarea className='form-control' onChange={(e) => setDetails(e.target.value)}>{details}</textarea>
          </div>

          <div className='btn-group'>
            <input type='submit' className='btn btn-primary btn-block' value='Credit User' />
          </div>
        </form>
        </div>

    </div>
</div>
</div></div>
  )
}

export default AddTransaction